<template>
  <v-container fill-height>
    <v-row rows="12">
      <v-col>
        <v-data-table :loading="!hydrated" :headers="headers" :items="feedback" :items-per-page="10" class="elevation-1">


<!--           <template v-slot:item.enabled="{ item }">
            <v-chip :color="item.enabled?'green': 'red'" dark>
              {{ item.enabled ? 'Yes': 'No' }}
            </v-chip>
          </template> -->

<!--           <template v-slot:item.email_verified="{ item }">
            <v-icon v-if="item.email_verified" small>
              checkmark
            </v-icon>
          </template> -->

          <template v-slot:item.message="{item}">
            <div v-html="item.message.replace(/\n/g, '<br/>')">
            </div>
          </template>

          <template v-slot:item.createdAt="{ item }">
            <div v-html="getDateFromStamp(item.createdAt)" small> </div>
          </template>


        </v-data-table>
      </v-col>
    </v-row>
  </v-container>

</template>

<script>

  import { listShazacinUserFeedbacks } from "@/graphql/queries"

  const headers = [
    // { text: 'Feedback Id', value: 'feedbackId' },
    { text: 'email', value: 'email' },
    { text: 'User Rating', value: 'rating' },
    { text: 'Message', value: 'message' },
    { text: 'Title ID?', value: 'titleId' },
    { text: 'Submitted', value: 'createdAt' },
  ]

  export default {
    name: 'UsersFeedback',
    data: () => ({
      headers,
      hydrated: false,
      feedback: []
    }),
    methods: {
      async hydrate() {
        let self = this;
        self.hydrated = false
        const result = await self.$Amplify.API.graphql({ query: listShazacinUserFeedbacks, fetchPolicy: 'network-only' })
        const feedback = result.data.listShazacinUserFeedbacks?.items
        if (!feedback) {
          console.error("Could not retrieve user feedback")
        }
        else {
          self.feedback = feedback.sort((a,b)=> -1)
        }
        self.hydrated = true
      },
      getDateFromStamp(timestamp) {
        let incomingDate = new Date(timestamp)
        let dateNow = Date.now()

        let d = Math.abs(incomingDate - dateNow) / 1000;                           // delta
        let r = {};                                                                // result
        let s = {                                                                  // structure
          year: 31536000,
          month: 2592000,
          week: 604800, // uncomment row to ignore
          day: 86400,   // feel free to add your own row
          hour: 3600,
          minute: 60,
          second: 1
        };

        let rtString = ''

        Object.keys(s).forEach(function (key) {
          r[key] = Math.floor(d / s[key]);
          d -= r[key] * s[key];
        });
        // console.info(`We have the time obj here as `, r)
        Object.keys(r).forEach((key) => {
          if (rtString == '' && r[key] > 0) {
            let keyStr = key
            keyStr = r[key] > 1 ? `${keyStr}s` : keyStr
            rtString = `${r[key]} ${keyStr} ago`
          }
        })
        return rtString
      },
    },
    mounted() {
      this.hydrate()
    }
  }
</script>

<style lang="scss" scoped>

</style>